// ** Actions Types for Product Gallery
const REQUEST_MANUFACTURER_GALLERY = "REQUEST_MANUFACTURER_GALLERY";
const REQUEST_MANUFACTURER_GALLERY_SUCCESS = "REQUEST_MANUFACTURER_GALLERY_SUCCESS";
const REQUEST_MANUFACTURER_GALLERY_FAILED = "REQUEST_MANUFACTURER_GALLERY_FAILED";
// ** Actions Types for Product Gallery Item
const REQUEST_MANUFACTURER_GALLERY_ITEM = "REQUEST_MANUFACTURER_GALLERY_ITEM";
const REQUEST_MANUFACTURER_GALLERY_ITEM_SUCCESS =
    "REQUEST_MANUFACTURER_GALLERY_ITEM_SUCCESS";
const REQUEST_MANUFACTURER_GALLERY_ITEM_FAILED =
    "REQUEST_MANUFACTURER_GALLERY_ITEM_FAILED";
//** Actions Types For Add Product Gallery
const REQUEST_ADD_MANUFACTURER_GALLERY = "REQUEST_ADD_MANUFACTURER_GALLERY";
const REQUEST_ADD_MANUFACTURER_GALLERY_SUCCESS =
    "REQUEST_ADD_MANUFACTURER_GALLERY_SUCCESS";
const REQUEST_ADD_MANUFACTURER_GALLERY_FAILED = "REQUEST_ADD_MANUFACTURER_GALLERY_FAILED";
//** Actions Types For Edit Product Gallery
const REQUEST_EDIT_MANUFACTURER_GALLERY = "REQUEST_EDIT_MANUFACTURER_GALLERY";
const REQUEST_EDIT_MANUFACTURER_GALLERY_SUCCESS =
    "REQUEST_EDIT_MANUFACTURER_GALLERY_SUCCESS";
const REQUEST_EDIT_MANUFACTURER_GALLERY_FAILED =
    "REQUEST_EDIT_MANUFACTURER_GALLERY_FAILED";

// ** Initial State
const initialState = {
    manufacturerGalleryList: [],
    manufacturerGalleryPagesCount: "",
    isLoadingGetManufacturerGalleryList: false,
    isFailedGetManufacturerGalleryList: false,
    // ** State For Product Gallery Item
    manufacturerGalleryItem: {},
    isLoadingManufacturerGalleryItem: false,
    isfailedManufacturerGalleryItem: false,
    // ** Add Product Gallery
    isloadingAddManufacturerGallery: false,
    successAddManufacturerGallery: false,
    isfailedAddManufacturerGallery: false,
    //** Edit Product Gallery
    isloadingEditManufacturerGallery: false,
    successEditManufacturerGallery: false,
    isfailedEditManufacturerGallery: false,
};

// Reducer
export default (state = initialState, action) => {
    switch (action.type) {
        // ** Reducers for Products Gallery List
        case REQUEST_MANUFACTURER_GALLERY:
            return {
                ...state,
                isLoadingGetManufacturerGalleryList: true,
            };
        case REQUEST_MANUFACTURER_GALLERY_SUCCESS:
            return {
                ...state,
                manufacturerGalleryList: action.manufacturerGalleryList,
                manufacturerGalleryPagesCount: action.manufacturerGalleryPagesCount,
                isLoadingGetManufacturerGalleryList: false,
                isFailedGetManufacturerGalleryList: false,
            };
        case REQUEST_MANUFACTURER_GALLERY_FAILED:
            return {
                ...state,
                isLoadingGetManufacturerGalleryList: false,
                isFailedGetManufacturerGalleryList: true,
            };
        // ** Reducers for Product Gallery Item
        case REQUEST_MANUFACTURER_GALLERY_ITEM:
            return {
                ...state,
                isLoadingManufacturerGalleryItem: true,
            };
        case REQUEST_MANUFACTURER_GALLERY_ITEM_SUCCESS:
            return {
                ...state,
                manufacturerGalleryItem: action.manufacturerGalleryItem,
                isLoadingManufacturerGalleryItem: false,
                isfailedManufacturerGalleryItem: false,
            };
        case REQUEST_MANUFACTURER_GALLERY_ITEM_FAILED:
            return {
                ...state,
                isLoadingManufacturerGalleryItem: false,
                isfailedManufacturerGalleryItem: true,
            };
        // ** Reducers for Add Product Gallery
        case REQUEST_ADD_MANUFACTURER_GALLERY:
            return {
                ...state,
                isloadingAddManufacturerGallery: true,
            };
        case REQUEST_ADD_MANUFACTURER_GALLERY_SUCCESS:
            return {
                ...state,
                isloadingAddManufacturerGallery: false,
                successAddManufacturerGallery: true,
                isfailedAddManufacturerGallery: false,
            };
        case REQUEST_ADD_MANUFACTURER_GALLERY_FAILED:
            return {
                ...state,
                isloadingAddManufacturerGallery: false,
                successAddManufacturerGallery: false,
                isfailedAddManufacturerGallery: true,
            };
        //**  Reducers for Edit Product Gallery
        case REQUEST_EDIT_MANUFACTURER_GALLERY:
            return {
                ...state,
                isloadingEditManufacturerGallery: true,
            };
        case REQUEST_EDIT_MANUFACTURER_GALLERY_SUCCESS:
            return {
                ...state,
                isloadingEditManufacturerGallery: false,
                successEditManufacturerGallery: true,
                isfailedEditManufacturerGallery: false,
            };
        case REQUEST_EDIT_MANUFACTURER_GALLERY_FAILED:
            return {
                ...state,
                isloadingEditManufacturerGallery: false,
                successEditManufacturerGallery: false,
                isfailedEditManufacturerGallery: true,
            };
        default:
            return state;
    }
};

// ** Actions for All Products Gallery
export const requestGetManufacturerGalleryList = () => {
    return {
        type: REQUEST_MANUFACTURER_GALLERY,
    };
};
export const requestGetManufacturerGalleryListSuccess = (
    manufacturerGalleryList,
    manufacturerGalleryPagesCount
) => {
    return {
        type: REQUEST_MANUFACTURER_GALLERY_SUCCESS,
        manufacturerGalleryList: manufacturerGalleryList,
        manufacturerGalleryPagesCount: manufacturerGalleryPagesCount,
    };
};
export const requestGetManufacturerGalleryListFailed = (message) => {
    return {
        type: REQUEST_MANUFACTURER_GALLERY_FAILED,
    };
};

// ** Actions for Product Gallery Item
export const requestGetManufacturerGalleryItem = () => {
    return {
        type: REQUEST_MANUFACTURER_GALLERY_ITEM,
    };
};
export const requestGetManufacturerGalleryItemSuccess = (manufacturerGalleryItem) => {
    return {
        type: REQUEST_MANUFACTURER_GALLERY_ITEM_SUCCESS,
        manufacturerGalleryItem: manufacturerGalleryItem,
    };
};
export const requestGetManufacturerGalleryItemFailed = () => {
    return {
        type: REQUEST_MANUFACTURER_GALLERY_ITEM_FAILED,
    };
};

//** Actions For Add Product Gallery
export const requestAddManufacturerGallery = () => {
    return {
        type: REQUEST_ADD_MANUFACTURER_GALLERY,
    };
};
export const requestAddManufacturerGallerySuccess = (message) => {
    return {
        type: REQUEST_ADD_MANUFACTURER_GALLERY_SUCCESS,
        message: message,
    };
};
export const requestAddManufacturerGalleryFaield = (message) => {
    return {
        type: REQUEST_ADD_MANUFACTURER_GALLERY_FAILED,
        message: message,
    };
};

//** Actions For Edit Product Gallery
export const requestEditManufacturerGallery = () => {
    return {
        type: REQUEST_EDIT_MANUFACTURER_GALLERY,
    };
};
export const requestEditManufacturerGallerySuccess = (message) => {
    return {
        type: REQUEST_EDIT_MANUFACTURER_GALLERY_SUCCESS,
        message: message,
    };
};
export const requestEditManufacturerGalleryFaield = (message) => {
    return {
        type: REQUEST_EDIT_MANUFACTURER_GALLERY_FAILED,
        message: message,
    };
};
